<template>
  <div>
    <div class="head">
      <div class="font-title">
        <span class="bigFont">{{ dataObj.title }}</span>
        <!--          <span class="smallFont">在未来，数字化将席卷和重塑所有的行业，没有所谓“传统行业”，唯有“以软件为核心”的公司，才能快速发展。</span>-->
        <!--          <span class="smallFont">产品设计能力，将越来越成为每个企业打造产品，实现业绩增长的核心动力。麦肯锡2018年10月首次发布设计指数（MDI）表明：设计指数排名前25%的公</span>-->
        <!--          <span class="smallFont">司，其收入和股东总回报(TRS)，比行业基准增长率，高出2倍。</span>-->
      </div>
      <!--        <div class="imgDiv">-->
      <!--          <img style="margin: 40px 0;" src="../../static/image/Company.jpg" height="245" width="100%"/>-->
      <!--        </div>-->
      <div class="font-title slep" style="margin-bottom: 30px;">
        <div v-html="dataObj.content"></div>
        <!--         <span class="bigFont">现在和未来的社会由软件支撑，软件制造的核心，在于产品设计</span>-->
        <!--         <span class="smallFont">在未来，数字化将席卷和重塑所有的行业，没有所谓“传统行业”，唯有“以软件为核心”的公司，才能快速发展。</span>-->
        <!--         <span class="smallFont">产品设计能力，将越来越成为每个企业打造产品，实现业绩增长的核心动力。麦肯锡2018年10月首次发布设计指数（MDI）表明：设计指数排名前25%的公</span>-->
        <!--         <span class="smallFont">司，其收入和股东总回报(TRS)，比行业基准增长率，高出2倍。</span>-->
      </div>
    </div>
  </div>
</template>
<script>
import {
  aboutUsQueryList,
  aboutUsQueryById
} from '../../api/industryInformation'
export default {
  name: 'CompanyProfile',
  data() {
    return {
      id: null,
      idDefault: null,
      dataObj: {}
    }
  },
  watch: {
    $route(to, from) {
      this.id = this.$route.query.id
      localStorage.setItem('aboutTypeId', this.id)
      this.getList()
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      await aboutUsQueryList().then(res => {
        console.log('res',res)
        for (let i = 0; i < res.length; i++) {
          this.idDefault = res[0].id
        }
      })
      this.id = this.$route.query.id
      if (this.id == null) {
        aboutUsQueryById(this.idDefault).then(res => {
          this.dataObj = res
        })
      } else {
        aboutUsQueryById(this.id).then(res => {
          this.dataObj = res
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.font-title {
  span {
    display: block;
    text-align: center;
  }
  .smallFont {
    font-size: 14px;
    line-height: 25px;
  }
  .bigFont {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  //margin-bottom: 40px;
}
.imgDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}
.font-title{
  line-height: 38px;
}
.slep{
  font-weight: 100;
}
</style>
